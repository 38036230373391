//components
import Text from "../../common/Text";

//styling
import styled, { useTheme } from "../../../styling/styled-components";

//images
import { ReactComponent as StreamLineFullLogo } from "../../../assets/vectors/StreamLineFullLogo.svg";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 100%;
    position: relative;
    padding-top: 14%;
`;

const TextWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xlarge}px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.small}px;
`;

const STRINGS = {
    HEADER: "Select a location above to view the production summary.",
};

export default function AllLocationScreen() {
    const { colors } = useTheme();

    return (
        <Container>
            <StreamLineFullLogo />
            <TextWrapper>
                <Text size="xlarge" type="header" color={colors.DEEP_BLUE_SEA}>
                    {STRINGS.HEADER}
                </Text>
            </TextWrapper>
        </Container>
    );
}
