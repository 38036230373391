//components
import Text from "../../common/Text";
import VerticalLine from "../../common/VerticalLine";
import LinkText from "../../common/LinkText";
import CardDetails from "./CardDetails";
import Loadable from "../../common/Loadable";
import ShimmerCard from "../../common/ShimmerCard";

//styling
import styled, { useTheme } from "../../../styling/styled-components";

//constants
import { ProductionStats } from "../../../API";

//react
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

//utils
import queryString from "query-string";
import { convertGramsToPounds } from "../../../utils/streamline/convertGramsToPounds";
import { checkCostCurrencyAndDisplayDollars } from "../../../utils/streamline/checkCostCurrencyAndDisplayDollars";

const CardDiv = styled.div`
    display: flex;
    width: 97%;
    height: 110px;
    padding: 0px ${({ theme }) => theme.spacing.large}px;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.medium}px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.BLANK_SLATE};
    ${({ theme }) =>
        theme.boxShadow &&
        `box-shadow: ${theme.shadowStyle.containerDropShadow}`}
`;

const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    flex: 1 0 0;
`;

const PlannedWastedContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) =>
        theme.isTablet ? theme.spacing.small : theme.spacing.large}px;
`;

const STRINGS = {
    PLANNED: "Planned",
    WASTED: "Wasted",
    VIEW: "View",
};

type Props = {
    cardData: ProductionStats;
};

export default function MenuItemCards({ cardData }: Props) {
    const location = useLocation();
    const navigate = useNavigate();
    const { colors, isTablet } = useTheme();
    const cardBoxShadow = cardData?.numObservations > 0 ? true : false;
    const textColor =
        cardData?.numObservations > 0 ? colors.DEEP_BLUE_SEA : colors.EXHAUST;

    const [searchParams, setSearchParams] = useSearchParams();
    const queryParams = queryString.parse(location.search);

    // date range: In order to make sure we're including all the observations for that specific menu item card,
    // we're using a range spanning from the day before the selected menu date, to the day after in the search
    const queryDate = new Date(queryParams?.date);
    const dayBefore = new Date(queryDate);
    dayBefore.setDate(queryDate.getDate() - 1);
    const dayAfter = new Date(queryDate);
    dayAfter.setDate(queryDate.getDate() + 1);
    const dateRange = [dayBefore, dayAfter];

    const formatValues = (number) => {
        if (typeof number == "string") {
            let [num, unit] = number?.split(" ");
            const roundNumber = Math?.round(num * 10) / 10;
            const formattedNumber = roundNumber?.toLocaleString();
            return `${formattedNumber} ${unit}`;
        } else {
            const roundNumber = Math?.round(number * 10) / 10;
            const formattedNumber = roundNumber?.toLocaleString();
            return formattedNumber;
        }
    };

    const convertPrimaryQty = (qty) => {
        if (qty) {
            return `${formatValues(convertGramsToPounds(qty)) + " " + "lbs"}`;
        }
    };

    const checkForPounds = (target, options) => {
        return options.some((unit) => target.split(" ").includes(unit));
    };

    const convertSecondaryQty = (
        primaryUOM,
        portionQty,
        portionUOM,
        showPortion = true
    ) => {
        const poundsOptions = ["pounds", "lbs", "lb", "pound"];

        if (!checkForPounds(primaryUOM, poundsOptions) && portionQty) {
            if (showPortion) {
                return `${formatValues(portionQty)} portions (${portionUOM
                    ?.toString()
                    ?.replace(/\b(portion|each)\b/g, "")} ea)`;
            } else {
                return `${formatValues(portionQty)} portions`;
            }
        } else {
            return "";
        }
    };

    const plannedCardDetails = {
        primaryQty: convertPrimaryQty(cardData?.planned?.secondaryQty) || "-",
        secondaryQty:
            convertSecondaryQty(
                cardData?.uoms?.primaryUoM,
                cardData?.planned?.portions,
                cardData?.uoms?.portionsUoM
            ) || "",
        label: STRINGS.PLANNED,
        numObservations: cardData?.numObservations,
    };

    const wastedCardDetails = {
        primaryQty: convertPrimaryQty(cardData?.postWaste?.secondaryQty) || "-",
        secondaryQty:
            convertSecondaryQty(
                cardData?.uoms?.primaryUoM,
                cardData?.postWaste?.portions,
                cardData?.uoms?.portionsUoM,
                false
            ) || "",
        label: STRINGS.WASTED,
        numObservations: cardData?.numObservations,
        value: cardData?.postWaste?.value
            ? `- ${checkCostCurrencyAndDisplayDollars(
                  cardData?.postWaste?.value,
                  "USD"
              )}`
            : "",
    };

    const ShimmerCardLoading = () => {
        return <ShimmerCard height={20} width={124} />;
    };

    const viewObservation = () => {
        const observationIdArray = cardData?.observations.map((obj) => obj);

        const removeEmptyString = observationIdArray.filter(
            (str) => str.trim() !== ""
        );

        searchParams.set("searchQuery", removeEmptyString.join(" OR "));
        searchParams.set("date", dateRange[0]);
        searchParams.append("date", dateRange[1]);

        //we want to include all ignored and unlabeled observations in the search
        searchParams.set("ignore", "false");
        searchParams.set("labeled", "false");

        setSearchParams(searchParams);
        navigate(`/dashboard/streamline/observations?${searchParams}`);
    };

    return (
        <CardDiv boxShadow={cardBoxShadow}>
            <MenuContainer>
                <Loadable
                    dataLoaded={cardData?.menuItemName}
                    LoadingComponent={ShimmerCardLoading}
                >
                    <Text
                        type="header"
                        size={isTablet ? "small" : "medium"}
                        color={textColor}
                    >
                        {cardData?.menuItemName}
                    </Text>
                    <Text
                        type="body"
                        size={isTablet ? "small" : "medium"}
                        color={colors.EXHAUST}
                    >
                        {cardData?.numObservations +
                            " observations" +
                            " " +
                            (cardData?.numObservations > 0 ? "- " : "")}
                        <LinkText
                            type={"new_link"}
                            size={"small"}
                            onClick={() => viewObservation()}
                        >
                            {cardData?.numObservations > 0
                                ? `${STRINGS.VIEW}`
                                : ""}
                        </LinkText>
                    </Text>
                </Loadable>
            </MenuContainer>
            <PlannedWastedContainer>
                <CardDetails cardDetails={plannedCardDetails} />
                <VerticalLine height={"68px"} />
                <CardDetails cardDetails={wastedCardDetails} />
            </PlannedWastedContainer>
        </CardDiv>
    );
}
