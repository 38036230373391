/*
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 * This module is deprecated, do not use this component, add to
 * the functionality, or fix any bugs with this. In all of these
 * cases you should work in the new Text component.
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 */

import React from "react";
import { TEXT_STYLES } from "../../styling";
import styled from "styled-components/macro";
import { findTextStyles } from "../../styling/styled-components";

type Props = {
    type?: keyof typeof TEXT_STYLES;
    size?: keyof typeof TEXT_STYLES.body &
        keyof typeof TEXT_STYLES.header &
        keyof typeof TEXT_STYLES.other &
        keyof typeof TEXT_STYLES.link;
    style?: React.CSSProperties | null;
    children?: JSX.Element | string | string[] | React.ReactNode | null;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    className?: string;
};

const StyledText = styled.div`
    ${({ type, size }) => findTextStyles(type, size)};
`;

export default function Component({
    type,
    size,
    style = null,
    children = null,
    onClick,
    className,
}: Props) {
    {
        if (!type && !size) {
            return (
                <StyledText
                    className={className}
                    onClick={onClick && onClick}
                    style={style}
                    type={type}
                    size={size}
                >
                    {children}
                </StyledText>
            );
        } else {
            const typeStyle = findTextStyles(type, size);

            return (
                <div
                    className={className}
                    onClick={onClick && onClick}
                    style={style}
                >
                    {children}
                </div>
            );
        }
    }
}
