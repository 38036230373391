//components
import AccordionLabel from "../../common/accordion/AccordionLabel";
import MenuItemCards from "./MenuItemCards";
import EmptyCardResults from "./EmptyCardResults";

//styling
import styled from "../../../styling/styled-components";

//constants
import { ProductionStats } from "../../../API";

const Wrapper = styled.div`
    margin-top: ${({ theme }) => theme.spacing.xmlarge}px;
`;

const CardWrapper = styled.div`
    margin-top: ${({ theme }) => theme.spacing.small}px;
`;

type Props = {
    stationData: ProductionStats[];
};

export default function StationAccordion({ stationData }: Props) {
    const stationName = {
        open:
            stationData[0]?.stationName +
            " " +
            `(${stationData[0]?.noResults ? 0 : stationData?.length})`,
        default:
            stationData[0]?.stationName +
            " " +
            `(${stationData[0]?.noResults ? 0 : stationData?.length})`,
    };

    return (
        <Wrapper>
            <AccordionLabel
                accordionText={stationName}
                openAccordion={stationData[0]?.noResults ? false : true}
                overlayId={stationData}
                size="large"
            >
                {stationData?.map((item, index) => {
                    if (item?.noResults) {
                        return (
                            <Wrapper>
                                <EmptyCardResults />
                            </Wrapper>
                        );
                    } else {
                        return (
                            <CardWrapper>
                                <MenuItemCards cardData={item} />
                            </CardWrapper>
                        );
                    }
                })}
            </AccordionLabel>
        </Wrapper>
    );
}
