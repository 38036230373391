//components
import Text from "../../common/Text";

//styling
import styled, { useTheme } from "../../../styling/styled-components";

const CardDiv = styled.div`
    display: flex;
    height: 60px;
    padding: 0px ${({ theme }) => theme.spacing.small}px;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    align-self: stretch;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.BLANK_SLATE};
`;

const STRINGS = {
    NO_RESULTS: "No results",
};
export default function EmptyCardResults() {
    const { colors, isTablet } = useTheme();

    return (
        <CardDiv>
            <Text type="label" size="small" color={colors.DEEP_BLUE_SEA}>
                {STRINGS.NO_RESULTS}
            </Text>
        </CardDiv>
    );
}
