// react
import { useEffect, useRef, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import queryString from "query-string";

// style
import styled, { useTheme } from "../../styling/styled-components";

// components
import RadioButtons from "./dropdown/RadioButtons";
import DropdownLabel from "./dropdown/DropdownLabel";
import ConfirmButton from "./dropdown/ConfirmButton";
import FlyoutForm from "./dropdown/FlyoutForm";
import OptionsContainer from "./dropdown/OptionsContainer";
import FormChildrenWrapper from "./dropdown/FormChildrenWrapper";

// assets
import { ReactComponent as LocationsWhite } from "../../assets/vectors/LocationsWhite.svg";
import { ReactComponent as LocationsBlue } from "../../assets/vectors/LocationsBlue.svg";

// utils
import useClickOutside from "../../utils/useClickOutside";
import { CHECKOUTS_RETURN_SUMMARY } from "../../constants/dashboard";

// styled components
const DropDownContainer = styled.div``;

const RadioForm = styled.div`
    width: 210px;
`;

export default function SingleSelectLocationDropdown({
    processLocationChange,
    locationItems,
    ...props
}: Props) {
    // state
    const [selectedValue, setSelectedValue] = useState("");

    const [disableButton, setDisableButton] = useState(true);
    const [showOptions, setShowOptions] = useState(false);

    let [searchParams, setSearchParams] = useSearchParams();

    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const locationId = queryParams?.locationIds;
    const locationLabel = locationItems.find(
        (item) => item.id === locationId
    )?.label;

    const [buttonLabel, setButtonLabel] = useState(
        locationLabel || CHECKOUTS_RETURN_SUMMARY.ALL_LOCATIONS
    );

    //refs click outside
    const dropdownRef = useRef("location-picker-dropdown-button");
    const flyoutRef = useRef("location-picker-flyout");

    useClickOutside(
        flyoutRef,
        () => {
            setShowOptions(false);
        },
        [dropdownRef]
    );

    useEffect(() => {
        setSelectedValue(locationId || "");
    }, []);

    // Helper functions
    const changeSelectionAndEnableButton = (event) => {
        event.preventDefault();
        const radioResult = event.target.value;
        setDisableButton(false);
        setSelectedValue(radioResult);
    };

    const submitLocationChangeAndUpdateLabel = (event) => {
        event.preventDefault();
        const form = event.target;
        const formJson = Object.fromEntries(new FormData(form).entries());
        const radioResult = formJson["optionSelected"];

        if (radioResult == "") {
            processLocationChange(null);
        } else {
            processLocationChange([radioResult]);
        }
        const filterIds = locationItems.filter(
            (value) => value.value == radioResult
        );

        const params = Object.fromEntries(searchParams);
        params.locationIds = radioResult;
        setSearchParams({ ...params });

        setButtonLabel(filterIds[0].label);
        setShowOptions(false);
    };

    const toggleDropdown = () => {
        setShowOptions(!showOptions);
    };

    const IconLabel = () => {
        if (showOptions) {
            return <LocationsWhite />;
        } else {
            return <LocationsBlue />;
        }
    };

    const LocationRadioButtons = () => {
        return (
            <RadioButtons
                options={locationItems}
                onChange={changeSelectionAndEnableButton}
                selectedOption={selectedValue}
            />
        );
    };

    const DropdownOptions = () => {
        return (
            <>
                {showOptions && (
                    <div ref={flyoutRef}>
                        <FlyoutForm
                            onSubmit={submitLocationChangeAndUpdateLabel}
                            alignRight={false}
                        >
                            <FormChildrenWrapper>
                                <RadioForm>
                                    <OptionsContainer>
                                        <LocationRadioButtons />
                                        <ConfirmButton
                                            clickable={!disableButton}
                                        />
                                    </OptionsContainer>
                                </RadioForm>
                            </FormChildrenWrapper>
                        </FlyoutForm>
                    </div>
                )}
            </>
        );
    };

    return (
        <DropDownContainer>
            <div ref={dropdownRef}>
                <DropdownLabel
                    toggleDropdown={toggleDropdown}
                    label={buttonLabel}
                    isOpen={showOptions}
                >
                    <IconLabel />
                </DropdownLabel>
            </div>
            <DropdownOptions />
        </DropDownContainer>
    );
}
