//components
import CarouselItem from "./CarouselItem";

export type Options = {
    name: string;
    id: string;
};

export type Indexes = {
    startIndex: string;
    currentIndexMenu: string;
};

type Props = {
    options: Options[];
    indexes: Indexes;
    onClickSelectTab: (indexSelected: number) => null;
};

export default function CarouselOptions({
    options,
    indexes,
    onClickSelectTab,
}: Props) {
    return (
        <>
            {options?.map((item, index) => {
                const absoluteIndex = index + indexes?.startIndex;
                const isActive = indexes?.currentIndexMenu == absoluteIndex;

                return (
                    <CarouselItem
                        isActive={isActive}
                        onClickSelectTab={() => onClickSelectTab(absoluteIndex)}
                        option={item}
                    />
                );
            })}
        </>
    );
}
