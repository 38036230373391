import styled from "../../../styling/styled-components";

const OptionsContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.small}px;
    max-height: 330px;
    overflow: scroll;
`;
export default function ScrollableOptionsContainer({ children }) {
    return <OptionsContainerDiv>{children}</OptionsContainerDiv>;
}
