import { ReactComponent as CalendarIcon } from "../../../assets/vectors/Calendar.svg";
import { ReactComponent as CalendarIconBlue } from "../../../assets/vectors/Calendar-Blue.svg";

export const CalendarIcons = ({ showDefault }: Boolean) => {
    if (showDefault) {
        return <CalendarIcon />;
    } else {
        return <CalendarIconBlue />;
    }
};
