//components
import Text from "../../common/Text";
import Loadable from "../../common/Loadable";
import ShimmerCard from "../../common/ShimmerCard";

//styling
import styled, { useTheme } from "../../../styling/styled-components";

const PlannedContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    ${({ theme }) =>
        theme.isTablet || theme.isMobile ? "width: 132px" : "width: 162px"}
`;

const LabelTextContainer = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

const LoadingDiv = styled.div`
    height: 12px;
    width: 124px;˝
`;

const ValueWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const ValueText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    padding-left: ${({ theme }) => theme.spacing.small}px;
`;

type CardDetails = {
    primaryQty: string;
    secondaryQty: string;
    label: string;
};

type Props = {
    cardDetails: CardDetails;
};

export default function CardDetails({ cardDetails }: Props) {
    const { colors, isTablet } = useTheme();

    const textColor = (text, numObservations) => {
        if (text[0] == "-" || numObservations == 0) {
            return colors.EXHAUST;
        } else {
            return colors.DEEP_BLUE_SEA;
        }
    };

    const ShimmerCardLoading = () => {
        return <LoadingDiv />;
    };

    return (
        <>
            <PlannedContainer>
                <Loadable
                    dataLoaded={cardDetails.primaryQty}
                    LoadingComponent={ShimmerCardLoading}
                >
                    <ValueWrapper>
                        <Text
                            type="header"
                            size={isTablet ? "medium" : "large"}
                            color={textColor(
                                cardDetails?.primaryQty,
                                cardDetails?.numObservations
                            )}
                        >
                            {cardDetails?.primaryQty}
                        </Text>
                        <ValueText
                            type="body"
                            size="medium"
                            color={colors.DEEP_BLUE_SEA}
                        >
                            {cardDetails?.value}
                        </ValueText>
                    </ValueWrapper>
                </Loadable>
                <Loadable
                    dataLoaded={cardDetails?.secondaryQty}
                    LoadingComponent={ShimmerCardLoading}
                >
                    <Text
                        type="body"
                        size={isTablet ? "small" : "medium"}
                        color={textColor(
                            cardDetails?.secondaryQty,
                            cardDetails?.numObservations
                        )}
                    >
                        {cardDetails?.secondaryQty}
                    </Text>
                </Loadable>
                <LabelTextContainer>
                    <Text type="label" size="small" color={colors.EXHAUST}>
                        {cardDetails?.label}
                    </Text>
                </LabelTextContainer>
            </PlannedContainer>
        </>
    );
}
