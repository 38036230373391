import { useRef, useState, useEffect } from "react";
import styled from "../../styling/styled-components";

// cmponents
import DropdownFilterButton from "../dashboard/filters/DropdownFilterButton";
import Button from "./Button";
import FlyoutForm from "./dropdown/FlyoutForm";
import ScrollableOptionsContainer from "./dropdown/ScrollableOptionsContainer";
import RadioButtons from "./dropdown/RadioButtons";
import Loadable from "./Loadable";
import FormChildrenWrapper from "./dropdown/FormChildrenWrapper";

// utils
import useClickOutside from "../../utils/useClickOutside";

// images
import { ReactComponent as BlueChevronUp } from "../../assets/vectors/BlueChevronUp.svg";
import { ReactComponent as WhiteChevronDown } from "../../assets/vectors/WhiteChevronDown.svg";

const ButtonsWrapper = styled.div`
    display: flex;
    padding-top: ${({ theme }) => theme.spacing.large}px;
    justify-content: center;
    align-content: center;
`;

const FilterWrapper = styled.div``;

const FlyoutWrapper = styled.div``;

const RadioForm = styled.div`
    width: 198px;
`;
const IconWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
`;

type DropdownOption = {
    id: string | number;
    name: string;
};

type Props = {
    options: DropdownOption[];
    onSubmit: (selectedValue: string | number) => void;
    initialSelection?: { value: string | number; label: string };
    isOpen: boolean;
    labels?: { apply: string };
};

export default function GlobalDropdown({
    dropdownConfig,
    onSubmit,
    initialSelected,
}: {
    dropdownConfig: Props;
}) {
    const { options, labels = { apply: "Apply Selection" } } = dropdownConfig;

    const dropdownArray = options.map(({ id, name }) => ({
        value: id,
        label: name,
    }));

    const [selectedValue, setSelectedValue] = useState();

    const [disableButton, setDisableButton] = useState(true);
    const [showOptions, setShowOptions] = useState(false);
    const [buttonLabel, setButtonLabel] = useState();
    const dropdownRef = useRef("dropdown-button");
    const flyoutRef = useRef("flyout");

    useClickOutside(flyoutRef, () => setShowOptions(false), [dropdownRef]);

    useEffect(() => {
        let initialIndex = 0;
        if (initialSelected) {
            dropdownArray.map((item, index) => {
                if (item.value === initialSelected) {
                    initialIndex = index;
                }
            });
        }
        setSelectedValue(dropdownArray[initialIndex]?.value);
        setButtonLabel(dropdownArray[initialIndex]?.label);
        if (
            dropdownArray[initialIndex]?.value &&
            dropdownArray[initialIndex]?.label
        ) {
            onSubmit(
                dropdownArray[initialIndex]?.value,
                dropdownArray[initialIndex]?.label
            );
        }
    }, []);

    const handleSelectionChange = (event, item) => {
        event.preventDefault();
        setDisableButton(false);

        setSelectedValue(item.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit(selectedValue, buttonLabel);
        closeDropdown();
        setButtonLabel(
            dropdownArray.find((item) => item.value === selectedValue)?.label
        );
    };

    const closeDropdown = () => {
        setShowOptions(false);
        setDisableButton(true);
    };

    const toggleDropdown = () => {
        if (!showOptions && !selectedValue) {
            setSelectedValue(
                dropdownArray[initialSelected]?.value || dropdownArray[0]?.value
            );
        }
        setShowOptions(!showOptions);
    };

    const IconLabel = () =>
        showOptions ? (
            <IconWrapper>
                <BlueChevronUp />
            </IconWrapper>
        ) : (
            <IconWrapper>
                <WhiteChevronDown />
            </IconWrapper>
        );

    const DropdownOptions = () => {
        return (
            <>
                {showOptions && (
                    <FlyoutWrapper ref={flyoutRef}>
                        <FlyoutForm onSubmit={handleSubmit} alignRight={false}>
                            <FormChildrenWrapper>
                                <RadioForm>
                                    <ScrollableOptionsContainer>
                                        <RadioButtons
                                            options={dropdownArray}
                                            onChange={handleSelectionChange}
                                            selectedOption={selectedValue}
                                        />
                                    </ScrollableOptionsContainer>
                                </RadioForm>
                                <ButtonsWrapper>
                                    <Button
                                        status={
                                            !disableButton
                                                ? "default"
                                                : "disabled"
                                        }
                                        size="small"
                                        type="primary"
                                        onClick={handleSubmit}
                                        label={labels.apply}
                                    />
                                </ButtonsWrapper>
                            </FormChildrenWrapper>
                        </FlyoutForm>
                    </FlyoutWrapper>
                )}
            </>
        );
    };

    return (
        <Loadable dataLoaded={dropdownConfig.isOpen}>
            <FilterWrapper>
                <div ref={dropdownRef}>
                    <DropdownFilterButton
                        onClick={toggleDropdown}
                        isSelected={!showOptions}
                    >
                        {buttonLabel}
                        <IconLabel />
                    </DropdownFilterButton>
                </div>
                <DropdownOptions />
            </FilterWrapper>
        </Loadable>
    );
}
