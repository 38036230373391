//components
import Text from "../../common/Text";

//styling
import styled, { useTheme } from "../../../styling/styled-components";

//utils
import { truncateString } from "../../../utils/helpers";

//constant
import { Options } from "./CarouselOptions";

const TextDiv = styled.div`
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
    cursor: pointer;
    white-space: nowrap;
`;

type Props = {
    isActive: Boolean;
    option: Options[];
    onClickSelectTab: () => null;
};

export default function CarouselItem({
    isActive,
    option,
    onClickSelectTab,
}: Props) {
    const { colors } = useTheme();

    return (
        <TextDiv>
            <Text
                size="medium"
                type="header"
                color={isActive ? colors.DEEP_BLUE_SEA : colors.EXHAUST}
                style={{
                    height: 30,
                    borderBottom: isActive
                        ? `3px solid ${colors.DEEP_BLUE_SEA}`
                        : `3px solid ${colors.SMOG}`,
                }}
                onClick={onClickSelectTab}
            >
                {truncateString(option?.name, 40)}
            </Text>
        </TextDiv>
    );
}
