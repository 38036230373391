//react
import { useContext, useEffect, useState, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

//context
import GlobalContext from "../../contexts/GlobalContext";

//styles
import styled from "../../styling/styled-components";

//components
import ScreenView from "../common/ScreenView";
import LearnMore from "./LearnMore";
import CarouselDatePicker from "../common/CarouselDatePicker";
import MenuTabs from "./productionSummary/MenuTabs";
import EventSearch from "../common/EventSearch";
import Displayable from "../common/Displayable";
import AllLocationScreen from "./productionSummary/AllLocationScreen";
import CheckBox from "./filters/CheckBox";
import TooltipAlert from "./productionSummary/TooltipAlert";

//utils
import { getTodaysDate } from "../../utils/streamline/getTodaysDate";
import queryString from "query-string";

/*These are the orgs that are ungated from seeing StreamLine*/
const SLUG_WHITELIST: string[] = [
    // staging
    "faber",
    // production
    "topanga-supply",
    "ohio-state",
    "appstate",
    "vandy",
    "bu",
];

const STRINGS = {
    HIDE_ITEMS: "Hide items with no observations",
};

const Container = styled(ScreenView)`
    position: relative;
    ${({ theme }) =>
        theme.isTablet || theme.isMobile
            ? `  `
            : `top: ${-theme.spacing.xxlarge}px;`}
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    ${({ theme }) =>
        theme.isTablet || theme.isMobile
            ? `flex-direction: column;
            justify-content: flex-start;`
            : "    justify-content: space-between;"}
`;

const StyledEventSearch = styled(EventSearch)`
    width: 400px;
`;

const SearchToggleWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const ToggleDiv = styled.div`
    padding-top: ${({ theme }) => theme.spacing.medium}px;
    ${({ theme }) =>
        theme.isTablet || theme.isMobile
            ? ` `
            : `text-align: right; margin-left: auto;`}
`;

const EventSearchWrapper = styled.div`
    ${({ theme }) =>
        theme.isTablet || theme.isMobile
            ? `padding-top: ${theme.spacing.large}px;`
            : `padding-top: ${theme.spacing.xxlarge}px;`}
`;

const TooltipWrapper = styled.div`
    position: relative;
    ${({ theme }) =>
        theme.isTablet || theme.isMobile
            ? ``
            : `top: -${theme.spacing.xlarge}px;`}
`;

export default function ProductionSummary() {
    let [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const eventSearch = useRef(null);

    const excludeEmpty = searchParams.get("excludeEmpty") || "TRUE";

    const [showAlert, setShowAlert] = useState(false);
    const popUpRef = useRef("today-date-alert");

    //set calendar dates
    const today = new Date();
    const queryDate = new Date(queryParams?.date);

    const [date, setDate] = useState(searchParams.getAll("date") || null);
    const [initialDate, setInitialDate] = useState({});

    let formattedQueryInitialDate = {
        year: queryDate.getFullYear(),
        month: queryDate.getMonth() + 1,
        day: queryDate.getDate(),
    };

    useEffect(() => {
        if (!queryParams.date) {
            searchParams.set("date", today);
            setShowAlert(true);
        } else {
            searchParams.set("date", date);
        }
        setSearchParams(searchParams);
    }, []);

    const formatDateString = (dateString) => {
        return dateString
            ?.toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
            })
            .replace(",", "");
    };

    useEffect(() => {
        checkInitialDate();
    }, []);

    useEffect(() => {
        if (formatDateString(today) == formatDateString(queryDate)) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [date]);

    const handleChangeDate = (date) => {
        setDate(date);
        searchParams.delete("daysBack");
        searchParams.delete("menuId");
        searchParams.set("date", date);
        setSearchParams(searchParams);
    };

    const checkInitialDate = () => {
        if (!formattedQueryInitialDate?.year) {
            setInitialDate(getTodaysDate());
        } else {
            setInitialDate(formattedQueryInitialDate);
        }
    };

    //search bar
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(
        searchParams.get("search") || ""
    );

    const clearSearchTerm = () => {
        setSearchTerm("");
    };

    const handleSearch = (term: string) => {
        setSearchTerm(term);
        term ? searchParams.set("search", term) : searchParams.delete("search");
        setSearchParams(searchParams);
    };

    // TEMP: This is temporary mechanism to block content
    const { slug } = useContext(GlobalContext).globalSelections;
    if (!SLUG_WHITELIST.includes(slug)) {
        return (
            <Container shouldShowCounter={false}>
                <LearnMore />
            </Container>
        );
    }
    //ENDTEMP

    return (
        <Container shouldShowCounter={false}>
            <Displayable
                displayPrimaryComponent={queryParams?.locationIds}
                SecondaryComponent={AllLocationScreen}
            >
                <HeaderWrapper>
                    <CarouselDatePicker
                        processDateChange={handleChangeDate}
                        initDate={initialDate}
                    />
                    <SearchToggleWrapper>
                        <EventSearchWrapper>
                            <StyledEventSearch
                                value={searchTerm}
                                loading={isLoading && searchTerm}
                                ref={eventSearch}
                                onChange={handleSearch}
                                showTags={false}
                                clearSearchTerm={clearSearchTerm}
                                menu={true}
                            />
                        </EventSearchWrapper>
                        <ToggleDiv>
                            <CheckBox
                                isSelected={excludeEmpty === "TRUE"}
                                onChange={(isSelected) => {
                                    searchParams.set(
                                        "excludeEmpty",
                                        isSelected ? "TRUE" : "FALSE"
                                    );
                                    setSearchParams(searchParams);
                                }}
                                label={STRINGS.HIDE_ITEMS}
                            />
                        </ToggleDiv>
                    </SearchToggleWrapper>
                </HeaderWrapper>
                <TooltipWrapper>
                    <TooltipAlert showAlert={showAlert} popUpRef={popUpRef} />
                </TooltipWrapper>
                <MenuTabs searchTerm={searchTerm} />
            </Displayable>
        </Container>
    );
}
