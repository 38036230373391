// style
import styled from "../../../styling/styled-components";

// components
import ConfirmButton from "./../dropdown/ConfirmButton";
import OptionsContainer from "./../dropdown/OptionsContainer";

// styled components
const Wrapper = styled.div``;

export default function CarouselConfirmContainer({ clickable }: boolean) {
    return (
        <Wrapper>
            <OptionsContainer>
                <ConfirmButton clickable={clickable} />
            </OptionsContainer>
        </Wrapper>
    );
}
