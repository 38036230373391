//styles
import styled from "../../styling/styled-components";

const LoadingStateDiv = styled.div`
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
`;

export default function WhiteLoadingCard({ width = "134", height = "20" }) {
    return <LoadingStateDiv width={width} height={height} />;
}
