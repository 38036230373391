//style
import styled, { useTheme, css } from "../../../styling/styled-components";

//components
import Button from "./../Button";
import Text from "./../Text";

const DropdownButton = styled(Button)`
    ${({ theme, isOpen }) =>
        !isOpen &&
        css`
            background-color: #fff;
            border: 1px solid transparent;
            &:hover {
                background-color: ${theme.colors.SEAGLASS};
            }
        `};
    border: 1px solid transparent;
    box-shadow: 0px 0px;
    margin-bottom: ${({ theme }) => theme.spacing.tiny}px;
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    min-width: 0;
    height: 36px;
`;

const ButtonLabelContainer = styled.div`
    align-items: center;
    display: flex;
`;

const ButtonLabel = styled(Text)`
    margin-left: ${({ theme }) => theme.spacing.xsmall}px;
    white-space: nowrap;
`;

type Props = {
    label: string;
    isOpen: boolean;
    toggleDropdown: () => void;
    size: string;
    children?: string | JSX.Element | JSX.Element[];
    className?: string;
};

export default function DropdownLabel({
    label,
    isOpen,
    toggleDropdown,
    size = "small",
    children,
    className,
}: Props) {
    const { colors } = useTheme();
    const labelColor = !isOpen ? colors.WAVE_STORM : colors.BLANK_SLATE;
    const labelType = size == "small" ? "label" : "header";
    const labelSize = size == "small" ? "small" : "medium";

    return (
        <DropdownButton
            isOpen={isOpen}
            onClick={toggleDropdown}
            className={className}
        >
            <ButtonLabelContainer>
                {children}
                <ButtonLabel
                    type={labelType}
                    size={labelSize}
                    color={labelColor}
                >
                    {label}
                </ButtonLabel>
            </ButtonLabelContainer>
        </DropdownButton>
    );
}
