//style
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import Displayable from "../../common/Displayable";

//react
import { RefObject } from "react";
import { useState } from "react";

//images
import { ReactComponent as Info } from "../../../assets/vectors/Info.svg";
import { ReactComponent as InfoFill } from "../../../assets/vectors/InfoFill.svg";

const Container = styled.div`
    display: flex;
    width: 210px;
    padding: ${({ theme }) => theme.spacing.small}px
        ${({ theme }) => theme.spacing.medium}px;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    border-radius: 5px;
    border: ${({ theme }) => theme.borderStyle.smallDivider};
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    box-shadow: ${({ theme }) => theme.shadowStyle.containerDropShadow};
`;

const AlertDiv = styled.div`
    position: absolute;
    white-space: normal;
`;

const TextDiv = styled.div`
    display: flex;
    flex-direction: row;
`;

const IconWrapper = styled.div``;

const StyledText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
    padding-right: ${({ theme }) => theme.spacing.tiny}px;
`;

type Props = {
    showAlert: boolean;
    popUpRef: RefObject;
};

const STRINGS = {
    DAILY_UPDATES: "Observations are updated throughout the day",
    TOOLTIP_ALERT:
        "Observations will be represented on the production summary page as they are received and labeled throughout the day. Observation values may change as labels and pan sizes are reviewed and updated. Observations are typically labeled, reviewed, and finalized within 4 hours of capture.",
};

//[TEC-2250] Refactor to create new common tooltip since this is the second time we're using this tooltip style

export default function TooltipAlert({ showAlert, popUpRef }: Props) {
    const { colors } = useTheme();
    const [hovered, setHovered] = useState(false);

    const InfoIcon = () => {
        return (
            <IconWrapper>
                <div
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    <Displayable
                        displayPrimaryComponent={hovered}
                        SecondaryComponent={Info}
                    >
                        <InfoFill />
                    </Displayable>
                </div>
            </IconWrapper>
        );
    };

    return (
        <Displayable displayPrimaryComponent={showAlert}>
            <TextDiv>
                <StyledText
                    type="body"
                    size="medium"
                    color={colors.DEEP_BLUE_SEA}
                >
                    {STRINGS.DAILY_UPDATES}
                </StyledText>
                <InfoIcon />
                <Displayable displayPrimaryComponent={hovered}>
                    <div ref={popUpRef}>
                        <AlertDiv>
                            <Container>
                                <Text
                                    type="body"
                                    size="small"
                                    color={colors.DEEP_BLUE_SEA}
                                >
                                    {STRINGS.TOOLTIP_ALERT}
                                </Text>
                            </Container>
                        </AlertDiv>
                    </div>
                </Displayable>
            </TextDiv>
        </Displayable>
    );
}
