//react
import { useState } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";

//styles
import styled, { useTheme } from "../../styling/styled-components";

//images
import { ReactComponent as ReusePassLogo } from "../../assets/vectors/ReusePassLogo.svg";
import { ReactComponent as Settings } from "../../assets/vectors/Settings.svg";
import { ReactComponent as StreamLine } from "../../assets/vectors/StreamLineLogo.svg";

//components
import { SidebarHeader } from "./SidebarHeader";
import { SidebarFooter } from "./SidebarFooter";
import Text from "../common/Text";
import { SidebarIcon } from "./common/SidebarIcon";
import { ReusePassActiveIcon } from "./ReusePassActiveIcon";
import { StreamLineActiveIcon } from "./StreamLineActiveIcon";

//constants
import { SIDEBAR } from "../../constants/sidebar";

const SidebarFooterWrapper = styled.div`
    margin-top: auto;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const SettingsWrapper = styled.div`
    margin-left: ${({ theme }) => theme.spacing.small}px;
    display: flex;
    flex-direction: column;
`;

const SettingsText = styled(Text)`
    position: relative;
    bottom: 28px;
    padding-left: ${({ theme }) => theme.spacing.small}px;
    margin-left: ${({ theme }) => theme.spacing.tiny}px;
`;

const ProductWrapper = styled.div``;

export default function NavSidebar() {
    const { broken, toggleSidebar } = useProSidebar();
    const { colors, spacing, borderStyle } = useTheme();
    const location = useLocation();
    const [keyboardFocused, setIsKeyboardFocused] = useState(false);
    const navigate = useNavigate();

    const [hoverStates, setHoverStates] = useState({
        reusepass: false,
        streamline: false,
        settings: false,
    });

    const reusePassActivePaths =
        location.pathname.includes("overview") ||
        location.pathname.includes("activity") ||
        location.pathname.includes("assets") ||
        location.pathname.includes("consumers");

    const settingsActivePaths =
        location.pathname.includes("settings/locations") ||
        location.pathname.includes("settings/scan-app") ||
        location.pathname.includes("settings/rentals");

    const settingTextColor = settingsActivePaths
        ? colors.BLANK_SLATE
        : colors.SUMMER_STORM;

    const handleHoverStates = (tab, isHovered) => {
        setHoverStates((prevStates) => ({
            ...prevStates,
            [tab]: isHovered,
        }));
    };

    const menuItemStyles = {
        fontSize: "14px",
        lineHeight: "21px",
        fontWeight: 400,
        fontFamily: "poppins",
        cursor: "pointer",
    };

    const menuIconStyle = {
        borderRadius: "50%",
        border: "1px solid",
    };

    const handleKeyDown = () => {
        setIsKeyboardFocused(true);
    };

    const handleMouseDown = () => {
        setIsKeyboardFocused(false);
    };

    const menuButtonStyle = ({ level, active }) => {
        const menuStyle = {
            cursor: "pointer",
            color: active ? colors.BLANK_SLATE : colors.SUMMER_STORM,
            backgroundColor: active
                ? colors.SEAGLASS_HOVER
                : colors.DEEP_BLUE_SEA,
            height: 64,
            paddingLeft: spacing.medium,
            "&:hover": {
                backgroundColor: active
                    ? colors.SEAGLASS_HOVER
                    : colors.ROCK_BOTTOM,
                color: active ? colors.BLANK_SLATE : colors.SUMMER_STORM,
            },
            "&:focus-visible": {
                outlineColor: "#BC72E8",
                marginBottom: spacing.tiny,
                marginLeft: spacing.tiny,
                marginRight: spacing.tiny,
            },
        };
        if (level === 0) {
            return menuStyle;
        }
    };

    const settingsButtonStyle = ({ level, active }) => {
        const menuStyle = {
            cursor: "pointer",
            color: active ? colors.BLANK_SLATE : colors.SUMMER_STORM,
            backgroundColor: active
                ? colors.SEAGLASS_HOVER
                : colors.DEEP_BLUE_SEA,
            height: 72,
            paddingBottom: 20,
            "&:hover": {
                backgroundColor: active
                    ? colors.SEAGLASS_HOVER
                    : colors.ROCK_BOTTOM,
                color: active ? colors.BLANK_SLATE : colors.SUMMER_STORM,
            },
            "&:focus-visible": {
                outlineColor: "#BC72E8",
                marginRight: 14,
                paddingRight: 0,
            },
        };

        if (level === 0) {
            return menuStyle;
        }
    };

    const ReusePassIcon = () => {
        return (
            <SidebarIcon
                Logo={ReusePassLogo}
                displayHoverLabel={hoverStates.reusepass}
                label={"ReusePass"}
            />
        );
    };

    const StreamLineIcon = () => {
        return (
            <SidebarIcon
                Logo={StreamLine}
                displayHoverLabel={hoverStates.streamline}
                label={"StreamLine"}
            />
        );
    };

    return (
        <Sidebar
            backgroundColor={colors.DEEP_BLUE_SEA}
            width="72px"
            style={{ zIndex: 1, overflow: "visible", position: "relative" }}
        >
            <div
                style={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <SidebarHeader
                    style={{
                        paddingBottom: spacing.large,
                        paddingTop: spacing.xsmall,
                    }}
                />
                <Menu
                    menuItemStyles={{
                        icon: menuIconStyle,
                        button: menuButtonStyle,
                    }}
                    onKeyDown={handleKeyDown}
                    onMouseDown={handleMouseDown}
                    className={keyboardFocused ? "keyboard-focused" : ""}
                >
                    <ProductWrapper
                        onMouseEnter={() =>
                            handleHoverStates("reusepass", true)
                        }
                        onMouseLeave={() =>
                            handleHoverStates("reusepass", false)
                        }
                    >
                        <MenuItem
                            rootStyles={menuItemStyles}
                            active={reusePassActivePaths}
                            component={
                                <Link
                                    onClick={() => broken && toggleSidebar()}
                                    to="/dashboard/overview"
                                />
                            }
                            icon={
                                <ReusePassActiveIcon
                                    ReusePassIcon={ReusePassIcon}
                                    displayHoverLabel={hoverStates.reusepass}
                                />
                            }
                        ></MenuItem>
                    </ProductWrapper>
                    <ProductWrapper
                        onMouseEnter={() =>
                            handleHoverStates("streamline", true)
                        }
                        onMouseLeave={() =>
                            handleHoverStates("streamline", false)
                        }
                    >
                        <MenuItem
                            active={location.pathname.includes("streamline")}
                            rootStyles={menuItemStyles}
                            component={
                                <Link
                                    onClick={() => broken && toggleSidebar()}
                                    to="/dashboard/streamline/production-summary"
                                />
                            }
                            icon={
                                <StreamLineActiveIcon
                                    StreamLineIcon={StreamLineIcon}
                                    displayHoverLabel={hoverStates.streamline}
                                />
                            }
                        ></MenuItem>
                    </ProductWrapper>
                </Menu>
                <SidebarFooterWrapper>
                    <SettingsWrapper
                        onMouseEnter={() => handleHoverStates("settings", true)}
                        onMouseLeave={() =>
                            handleHoverStates("settings", false)
                        }
                    >
                        <Menu
                            menuItemStyles={{
                                button: settingsButtonStyle,
                            }}
                            onKeyDown={handleKeyDown}
                            onMouseDown={handleMouseDown}
                            className={
                                keyboardFocused ? "keyboard-focused" : ""
                            }
                        >
                            <MenuItem
                                active={settingsActivePaths}
                                rootStyles={menuItemStyles}
                                component={
                                    <Link
                                        onClick={() =>
                                            broken && toggleSidebar()
                                        }
                                        to="/dashboard/settings/locations"
                                    />
                                }
                                icon={<Settings />}
                            ></MenuItem>
                            <SettingsText
                                type="label"
                                color={settingTextColor}
                                size="small"
                                onClick={() =>
                                    navigate("/dashboard/settings/locations")
                                }
                            >
                                {SIDEBAR.SETTINGS}
                            </SettingsText>
                        </Menu>
                    </SettingsWrapper>
                    <SidebarFooter />
                </SidebarFooterWrapper>
            </div>
        </Sidebar>
    );
}
