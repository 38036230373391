export const ERROR_MESSAGES = {
    CODE_REQUIREMENTS: "Your confirmation code should be 6 digits.",
    CORRECT_TITLE: "Please correct the errors below and then try again.",
    INVALID_EMAIL: "Please enter a valid email address",
    MAX_CODE_RESEND:
        "You’ve reached the maximum number of times you can resend the code. Please try again later.",
    MAX_RETRY:
        "Sorry, you’ve reached the maximum number of attempts to enter the correct confirmation code.",
    PASSWORD_CONFIRMATION: "That password doesn’t match the one entered above.",
    PASSWORD_CONSTRAINTS: "That password doesn’t meet our requirements.",
    PASSWORD_REQUIREMENTS:
        "Must be a different password with atleast 8 characters and include 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number.",
    PASSWORD_NOT_RECOGNIZED:
        "The password you entered doesn’t match our records. Please try again.",
    TEMP_PASSWORD_EXPIRED_PART1:
        "Your temporary password has expired. Temporary passwords expire after 30 days. Please reach out to ",
    TEMP_PASSWORD_EXPIRED_PART2: " to request a new temporary password.",
    USER_NOT_FOUND:
        "There is no account associated with that email. Please check your email for your dashboard invite and use the provided temporary password to sign in. If the temporary password was sent more than 30 days ago, you will need to request a new one by reaching out to ",
    PASSWORD_REQUEST_EMAIL: "techsupport@topanga.io",
    UNABLE_TO_RESET_PASSWORD_P1:
        "If you’ve never signed into the dashboard before and are attempting to reset a temporary password, you will need to request a new one. Please reach out to ",
    UNABLE_TO_RESET_PASSWORD_P2: " for assistance.",
};

export const ERROR_TYPES = {
    MAX_CODE_RESEND_TYPE: "LimitExceededException",
    PASSWORD_NOT_RECOGNIZED_TYPE: "NotAuthorizedException",
    TEMP_PASSWORD_EXPIRED_TYPE:
        "Temporary password has expired and must be reset by an administrator.",
    UNABLE_TO_RESET_PASSWORD:
        "User password cannot be reset in the current state.",
    USER_NOT_FOUND_TYPE: "UserNotFoundException",
};

export const CODE_ERRORS = {
    ATTEMPT_PROMPT: " attempt remaining",
    ATTEMPTS_PROMPT: " attempts remaining",
    CODE_REQUIREMENTS: "Your confirmation code should be 6 digits.",
    CODE_SENT: "Sorry, that doesn’t match our records. Please try again.",
    TRY_AGAIN_LATER:
        "Please try again later or contact an account administrator for help.",
};

export const API_ERROR = "Sorry, something went wrong. Please try again.";
