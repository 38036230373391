//style
import styled from "../../styling/styled-components";

const Circle = styled.div`
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: ${({ theme }) => theme.colors.SEAGLASS};
        opacity: 1;
        cursor: pointer;
    }
`;

export default function HighlightHover({ children, ...props }: Props) {
    return <Circle>{children}</Circle>;
}
