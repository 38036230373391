// react
import { ReactNode } from "react";

// style
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css";
import styled, { css } from "styled-components/macro";

// components
import Displayable from "../Displayable";

// assets
import { ReactComponent as RightArrow } from "../../../assets/vectors/RightArrow.svg";
import { ReactComponent as LeftArrow } from "../../../assets/vectors/LeftArrow.svg";
import { ReactComponent as RightArrowMarineLayer } from "../../../assets/vectors/RightArrowMarineLayer.svg";

//styled components
const CarouselWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

const NextDateButton = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    padding: ${({ theme }) => theme.spacing.xsmall}px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.BLANK_SLATE};
    user-select: none;
    ${({ theme, disabled }) =>
        disabled
            ? css`
                  pointer-evemts: none;
              `
            : css`
                  &:hover {
                      background-color: ${theme.colors.SEAGLASS};
                      cursor: pointer;
                  }
              `};
`;

const ArrowWrapper = styled.div`
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
    margin-left: ${({ theme }) => theme.spacing.tiny}px;
    padding-bottom: ${({ theme }) => theme.spacing.tiny}px;
`;

type Props = {
    goToNextDay: () => void;
    goToPreviousDay: () => void;
    rightButtonDisabled: boolean;
    children: ReactNode;
};

export default function CarouselDateButtons({
    goToPreviousDay,
    goToNextDay,
    rightButtonDisabled,
    children,
}: Props) {
    const DisabledRightArrow = () => {
        return <RightArrowMarineLayer />;
    };

    return (
        <CarouselWrapper>
            <NextDateButton onClick={goToPreviousDay}>
                <LeftArrow />
            </NextDateButton>
            {children}
            <NextDateButton
                onClick={goToNextDay}
                disabled={rightButtonDisabled}
            >
                <Displayable
                    displayPrimaryComponent={!rightButtonDisabled}
                    SecondaryComponent={DisabledRightArrow}
                >
                    <ArrowWrapper>
                        <RightArrow />
                    </ArrowWrapper>
                </Displayable>
            </NextDateButton>
        </CarouselWrapper>
    );
}
