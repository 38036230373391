//components
import HighlightHover from "../../common/HighlightHover";
import Displayable from "../../common/Displayable";
import CarouselOptions from "./CarouselOptions";

//styling
import styled from "../../../styling/styled-components";

//react
import { useState, useEffect } from "react";

// assets
import { ReactComponent as RightArrow } from "../../../assets/vectors/RightArrow.svg";
import { ReactComponent as LeftArrow } from "../../../assets/vectors/LeftArrow.svg";
import { ReactComponent as RightArrowMarineLayer } from "../../../assets/vectors/RightArrowMarineLayer.svg";
import { ReactComponent as LeftArrowMarineLayer } from "../../../assets/vectors/LeftArrowMarineLayer.svg";

const MenuContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xlarge}px;
    flex: 1 0 0;
`;

type TabOptions = {
    name: string;
    id: string;
};

type Props = {
    tabOptions: TabOptions[];
    handleTabChange: (integer: Integer) => null;
    initialIndex: Number;
};

export default function MenuCarousel({
    tabOptions,
    handleTabChange,
    initialSelected,
}: Props) {
    const [currentIndexMenu, setCurrentIndexMenu] = useState();
    const [startIndex, setStartIndex] = useState(0);

    const itemsToShow = 4;

    const visibleTabs = tabOptions?.slice(startIndex, startIndex + itemsToShow);

    const handleNext = () => {
        setStartIndex((prevIndex) =>
            Math.min(prevIndex + 1, tabOptions.length - itemsToShow)
        );
        setCurrentIndexMenu((prevIndex) => prevIndex);
    };

    const handlePrev = () => {
        setStartIndex((prevIndex) => Math.max(prevIndex - itemsToShow, 0));
    };

    useEffect(() => {
        let initialIndex = 0;
        if (initialSelected) {
            tabOptions.map((item, index) => {
                if (item.name === initialSelected) {
                    initialIndex = index;
                }
            });
        }
        setCurrentIndexMenu(initialIndex);
    }, []);

    useEffect(() => {
        tabOptions?.map((item, index) => {
            if (currentIndexMenu == index) {
                handleTabChange(item?.name);
                return;
            }
        });
    }, [currentIndexMenu]);

    const onClickSelectTab = (index) => {
        setCurrentIndexMenu(index);
        handleTabChange(tabOptions[index]?.id, tabOptions[index]?.name);
    };

    const indexes = {
        startIndex: startIndex,
        currentIndexMenu: currentIndexMenu,
    };

    return (
        <MenuContainer>
            <Displayable
                displayPrimaryComponent={startIndex > 0}
                SecondaryComponent={LeftArrowMarineLayer}
            >
                <div onClick={() => handlePrev()}>
                    <HighlightHover>
                        <LeftArrow />
                    </HighlightHover>
                </div>
            </Displayable>
            <CarouselOptions
                options={visibleTabs}
                indexes={indexes}
                onClickSelectTab={onClickSelectTab}
            />
            <Displayable
                displayPrimaryComponent={
                    startIndex + itemsToShow < tabOptions?.length
                }
                SecondaryComponent={RightArrowMarineLayer}
            >
                <div onClick={() => handleNext()}>
                    <HighlightHover>
                        <RightArrow />
                    </HighlightHover>
                </div>
            </Displayable>
        </MenuContainer>
    );
}
