//images
import { ReactComponent as Overview } from "../assets/vectors/Overview.svg";
import { ReactComponent as Pulse } from "../assets/vectors/Pulse.svg";
import { ReactComponent as Consumer } from "../assets/vectors/Consumer.svg";
import { ReactComponent as Assets } from "../assets/vectors/Assets.svg";
import { ReactComponent as Clipboard } from "../assets/vectors/Clipboard.svg";

export const reusePassTabs = [
    {
        label: "Overview",
        Icon: Overview,
        selected: true,
        url: "/dashboard/overview",
        hover: false,
    },
    {
        label: "Activity",
        Icon: Pulse,
        selected: false,
        url: "/dashboard/activity/rentals",
        hover: false,
    },
    {
        label: "Assets",
        Icon: Assets,
        selected: false,
        url: "/dashboard/assets",
        hover: false,
    },
    {
        label: "Consumers",
        Icon: Consumer,
        selected: false,
        url: "/dashboard/consumers",
        hover: false,
    },
];

export const streamLineTabs = [
    {
        label: "Production summary",
        Icon: Clipboard,
        selected: true,
        url: "/dashboard/streamline/production-summary",
        hover: false,
    },
    {
        label: "Observation log",
        Icon: Pulse,
        selected: false,
        url: "/dashboard/streamline/observations",
        hover: false,
    },
];

export const settingsTabs = [
    {
        label: "Locations",
        Icon: null,
        selected: true,
        url: "/dashboard/settings/locations",
        hover: false,
    },
    {
        label: "ScanApp Accounts",
        Icon: null,
        selected: false,
        url: "/dashboard/settings/scan-app",
        hover: false,
    },
    {
        label: "Rentals",
        Icon: null,
        selected: false,
        url: "/dashboard/settings/rentals",
        hover: false,
    },
];

export const products = {
    "/dashboard/overview": {
        tabOptions: reusePassTabs,
        productName: "ReusePass",
    },
    "/dashboard/streamline": {
        tabOptions: streamLineTabs,
        productName: "StreamLine",
    },
    "/dashboard/settings/locations": {
        tabOptions: settingsTabs,
        productName: "Settings",
    },
};
