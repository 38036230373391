//components
import AccordionLabel from "../../common/accordion/AccordionLabel";
import ShimmerCard from "../../common/ShimmerCard";

//styling
import styled, { useTheme } from "../../../styling/styled-components";

const Wrapper = styled.div`
    margin-top: ${({ theme }) => theme.spacing.large}px;
`;

const CardDiv = styled.div`
    display: flex;
    width: 97%;
    height: 110px;
    padding: 0px ${({ theme }) => theme.spacing.large}px;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.medium}px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.BLANK_SLATE};
`;

const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    flex: 1 0 0;
`;

export default function LoadingCard({ cardData }: Props) {
    const ShimmerCardLoading = () => {
        return <ShimmerCard height={20} width={124} />;
    };

    return (
        <Wrapper>
            <CardDiv>
                <MenuContainer>
                    <ShimmerCardLoading />
                </MenuContainer>
                <ShimmerCardLoading />
                <ShimmerCardLoading />
            </CardDiv>
        </Wrapper>
    );
}
