//style
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import Displayable from "../../common/Displayable";
import Loadable from "../../common/Loadable";
import LocationFilter from "../../dashboard/checkoutreturns/LocationFilter";

//react
import { useState, useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { ProductHeader } from "../common/TitleText";

//constants
import {
    reusePassTabs,
    streamLineTabs,
    settingsTabs,
} from "../../../constants/secondaryNav";

//utils
import queryString from "query-string";

const TabWrapper = styled.div`
    display: flex;
    padding: ${({ theme }) => theme.spacing.small}px 0px;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.small}px;
`;

const IconTextWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.small}px;
    align-self: stretch;
`;

const HighlightWrapper = styled.div`
    cursor: pointer;
    display: flex;
    padding: 0px ${({ theme }) => theme.spacing.small}px;
    justify-content: center;
    align-items: center;
    height: 50px;
    gap: ${({ theme }) => theme.spacing.small}px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.BLANK_SLATE};
    ${(props) =>
        props.selected
            ? `background-color: ${props.theme.colors.SEAGLASS};
             color: ${props.theme.colors.DEEP_BLUE_SEA};
          `
            : ``}
    &:hover {
        ${(props) =>
            !props.selected
                ? `border-radius: 4px;
                 border: 1px solid ${props.theme.colors.ROCK_BOTTOM}`
                : `text-decoration: underline;`}
    }
`;

const TabText = styled(Text)`
    color: ${({ theme }) => theme.colors.EXHAUST};
    &:hover {
        color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    }
    ${(props) =>
        props.selected && `color: ${props.theme.colors.DEEP_BLUE_SEA};`}
`;

export const HeaderSecondaryNav = () => {
    const { colors, isDesktop } = useTheme();
    const location = useLocation();
    const navigate = useNavigate();

    const [tabOptions, setTabOptions] = useState([]);
    const [productName, setProductName] = useState("");
    const [loaded, setLoaded] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const queryParams = queryString.parse(location.search);
    const queryDate = new Date(queryParams?.date);

    //location filter
    const [parentLocationIds, setParentLocationIds] = useState(null);

    const products = {
        "/dashboard/overview": {
            tabOptions: reusePassTabs,
            productName: "ReusePass",
        },
        "/dashboard/streamline/production-summary": {
            tabOptions: streamLineTabs,
            productName: "StreamLine",
            locationIds: parentLocationIds,
        },
        "/dashboard/settings/locations": {
            tabOptions: settingsTabs,
            productName: "Settings",
        },
    };

    const productPath = products[location.pathname];
    const matchUrl = Object.values(products).find((productObj) =>
        productObj.tabOptions.some((tab) => tab.url.includes(location.pathname))
    );
    const matchParentLocation = location.pathname.includes(
        "/dashboard/streamline/"
    );

    let previousSearchParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        if (productPath) {
            setTabOptions(productPath.tabOptions);
            setProductName(productPath.productName);
        } else if (matchUrl) {
            let tabPage = matchUrl.tabOptions.find((obj) =>
                obj.url.includes(location.pathname)
            );
            handleNavigation(tabPage);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (location.pathname) {
            findInitialPath();
        }
    }, []);

    useEffect(() => {
        if (tabOptions.length > 0) {
            setLoaded(true);
        }
    }, [tabOptions]);

    const handleChangeLocation = (ids) => {
        if (ids) {
            const filterIds = ids.filter((value) => value !== null);
            setParentLocationIds(filterIds);
        } else {
            setParentLocationIds(ids);
        }
    };

    const findInitialPath = () => {
        if (location.pathname.includes("settings")) {
            setProductName("Settings");
            handleInitialNavigation(location.pathname, settingsTabs);
            return;
        }
        if (location.pathname.includes("streamline")) {
            setProductName("StreamLine");
            handleInitialNavigation(location.pathname, streamLineTabs);
            return;
        } else {
            setProductName("ReusePass");
            handleInitialNavigation(location.pathname, reusePassTabs);
            return;
        }
    };

    const handleInitialNavigation = (tabItem, tabs) => {
        const updatedTabs = tabs.map((item) =>
            `${item.url.toString().trim()}/` == tabItem.toString().trim()
                ? { ...item, selected: true }
                : { ...item, selected: false }
        );

        setTabOptions(updatedTabs);
    };

    const handleNavigation = (tabItem) => {
        const updatedTabs = tabOptions.map((item) =>
            item.label == tabItem.label
                ? { ...item, selected: true }
                : { ...item, selected: false }
        );
        if (matchParentLocation && parentLocationIds) {
            searchParams.set("locationIds", parentLocationIds[0]);
            setSearchParams(searchParams);
            navigate(tabItem.url + `?${searchParams}`);
        } else {
            navigate(tabItem.url);
        }
        setTabOptions(updatedTabs);
    };

    return (
        <Displayable
            displayPrimaryComponent={
                isDesktop || location.pathname.includes("streamline")
            }
        >
            <ProductHeader productName={productName}>
                <Displayable
                    displayPrimaryComponent={location.pathname.includes(
                        "streamline"
                    )}
                >
                    <LocationFilter
                        handleChangeLocation={handleChangeLocation}
                    />
                </Displayable>
                <Loadable dataLoaded={loaded}>
                    {tabOptions?.map((item) => {
                        const iconColor = item.selected
                            ? colors.DEEP_BLUE_SEA
                            : colors.EXHAUST;
                        const tabTextType = item.selected
                            ? "label_bold"
                            : "label";
                        return (
                            <>
                                <TabWrapper>
                                    <IconTextWrapper
                                        onClick={() => handleNavigation(item)}
                                    >
                                        <HighlightWrapper
                                            selected={item.selected}
                                            tabIndex={0}
                                        >
                                            <Displayable
                                                displayPrimaryComponent={
                                                    item?.Icon
                                                }
                                            >
                                                <item.Icon color={iconColor} />
                                            </Displayable>
                                            <TabText
                                                type={tabTextType}
                                                size="medium"
                                                selected={item.selected}
                                            >
                                                {item.label}
                                            </TabText>
                                        </HighlightWrapper>
                                    </IconTextWrapper>
                                </TabWrapper>
                            </>
                        );
                    })}
                </Loadable>
            </ProductHeader>
        </Displayable>
    );
};
