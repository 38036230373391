// react
import { useEffect, useRef, useState } from "react";

// style
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css";
import styled, { useTheme } from "styled-components/macro";

// components
import { Calendar, utils } from "@amir04lm26/react-modern-calendar-date-picker";
import Line from "./Line";
import DropdownLabel from "./dropdown/DropdownLabel";
import FlyoutForm from "./dropdown/FlyoutForm";
import FormChildrenWrapper from "./dropdown/FormChildrenWrapper";
import Displayable from "./Displayable";
import { CalendarIcons } from "./datepicker/CalendarIcons";
import CarouselConfirmContainer from "./datepicker/CarouselFlyoutForm";
import CarouselDateButtons from "./datepicker/CarouselDateButtons";
import Text from "./Text";

// utils
import { format, isToday, isYesterday } from "date-fns";
import useClickOutside from "../../utils/useClickOutside";
import { areObjectsIdentical } from "../../utils/streamline/areObjectsIdentical";
import { getTodaysDate } from "../../utils/streamline/getTodaysDate";

const STRINGS = {
    CALENDAR_LABEL_START: (date: string) => `Start/End: ${date || ""}`,
};

// styled components
const CalendarContainer = styled.div``;

const CarouselContainer = styled.div`
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.tiny}px;
`;

type Props = {
    initDate?: Object;
    processDateChange: (date: string) => void;
};

export default function CarouselDatePicker({
    initDate,
    processDateChange,
}: Props) {
    // state
    const [buttonLabel, setButtonLabel] = useState("");
    const [showOptions, setShowOptions] = useState(false);
    const [submitButtonClickable, setSubmitButtonClickable] = useState(false);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [calendarDateLabel, setCalendarDateLabel] = useState(
        STRINGS.CALENDAR_LABEL_START("")
    );
    const [confirmButtonClicked, setConfirmButtonClicked] = useState(false);

    const { colors } = useTheme();

    //refs click outside
    const dropdownRef = useRef("date-picker-dropdown-button");
    const flyoutRef = useRef("date-picker-flyout");

    useClickOutside(
        flyoutRef,
        () => {
            setShowOptions(false);
        },
        [dropdownRef]
    );

    // Helper functions
    const toDateObject = ({ year, month, day }) =>
        new Date(year, month - 1, day);

    const formatButtonLabel = (date) => {
        if (isToday(date)) {
            return `Today, ${format(date, "eeee, MMM. d, yyyy")}`;
        }
        if (isYesterday(date)) {
            return `Yesterday, ${format(date, "eeee, MMM. d, yyyy")}`;
        }
        return format(date, "eeee, MMM. d, yyyy");
    };

    const formatCalendarLabel = (date) => {
        return format(date, " MMM. d, yyyy");
    };

    const toCustomFormat = (date) => ({
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
    });

    const checkSubmitButtonStatus = () => {
        setSubmitButtonClickable(true);
    };

    const setCalendarDate = (date) => {
        setSelectedDate(date);
        let calendarDate = toDateObject(date);

        setCalendarDateLabel(
            STRINGS.CALENDAR_LABEL_START(formatCalendarLabel(calendarDate))
        );
    };

    const submitDateChange = (event) => {
        event.preventDefault();
        let newCalendarDate = toDateObject(selectedDate);

        setButtonLabel(formatButtonLabel(newCalendarDate));
        setCalendarDateLabel(
            STRINGS.CALENDAR_LABEL_START(formatCalendarLabel(newCalendarDate))
        );
        processDateChange(new Date(newCalendarDate));
        setShowOptions(false);
    };

    const updateSelectedDateAndLabel = (date) => {
        let calendarDate = toDateObject(date);

        setButtonLabel(formatButtonLabel(calendarDate));
        setCalendarDateLabel(STRINGS.CALENDAR_LABEL_START(""));
        processDateChange(date);
    };

    const toggleDropdown = () => {
        setShowOptions(!showOptions);
    };

    //carousel buttons
    const goToPreviousDay = () => {
        let newDate;
        setConfirmButtonClicked(true);
        setSelectedDate((prevDate) => {
            const dateObj = toDateObject(prevDate);
            dateObj.setDate(dateObj.getDate() - 1);
            newDate = toCustomFormat(dateObj);
            return toCustomFormat(dateObj);
        });
        if (newDate) {
            updateSelectedDateAndLabel(newDate);
            setShowOptions(false);
        }
    };

    const goToNextDay = () => {
        let newDate;
        if (nextButtonDisabled) {
            return;
        } else {
            setConfirmButtonClicked(true);
            let newSelectedDate = setSelectedDate((prevDate) => {
                const dateObj = toDateObject(prevDate);
                dateObj.setDate(dateObj.getDate() + 1);
                return toCustomFormat(dateObj);
            });
            if (newDate) {
                updateSelectedDateAndLabel(newSelectedDate);
                setShowOptions(false);
            }
        }
    };

    //effects
    useEffect(() => {
        if (initDate?.year) {
            setSelectedDate(initDate);
            let newCalendarDate = toDateObject(initDate);

            setButtonLabel(formatButtonLabel(newCalendarDate));

            processDateChange(new Date(newCalendarDate));
        }
    }, [initDate]);

    useEffect(() => {
        checkSubmitButtonStatus();
        setNextButtonDisabled(false);
        setConfirmButtonClicked(false);
        if (areObjectsIdentical(selectedDate, getTodaysDate())) {
            setNextButtonDisabled(true);
        }
        if (selectedDate && confirmButtonClicked) {
            let newCalendarDate = toDateObject(selectedDate);

            setButtonLabel(formatButtonLabel(newCalendarDate));
            setCalendarDateLabel(
                STRINGS.CALENDAR_LABEL_START(
                    formatCalendarLabel(newCalendarDate)
                )
            );
            processDateChange(new Date(newCalendarDate));
        }
    }, [selectedDate]);

    // subcomponents
    const CalendarForm = () => {
        return (
            <CalendarContainer>
                <Text type="label" size="medium" color={colors.DEEP_BLUE_SEA}>
                    {calendarDateLabel}
                </Text>
                <Line />
                <Calendar
                    value={selectedDate}
                    onChange={setCalendarDate}
                    maximumDate={utils().getToday()}
                    colorPrimary={colors.WAVE_STORM}
                    colorPrimaryLight={colors.MARINE_LAYER}
                />
            </CalendarContainer>
        );
    };

    return (
        <CarouselContainer>
            <div ref={dropdownRef}>
                <CarouselDateButtons
                    goToPreviousDay={goToPreviousDay}
                    goToNextDay={goToNextDay}
                    rightButtonDisabled={nextButtonDisabled}
                >
                    <DropdownLabel
                        toggleDropdown={toggleDropdown}
                        label={buttonLabel}
                        isOpen={showOptions}
                        size="large"
                    >
                        <CalendarIcons showDefault={showOptions} />
                    </DropdownLabel>
                </CarouselDateButtons>
            </div>
            <Displayable displayPrimaryComponent={showOptions}>
                <div ref={flyoutRef}>
                    <FlyoutForm onSubmit={submitDateChange} alignRight={false}>
                        <FormChildrenWrapper>
                            <CalendarForm />
                            <CarouselConfirmContainer
                                clickable={submitButtonClickable}
                            />
                        </FormChildrenWrapper>
                    </FlyoutForm>
                </div>
            </Displayable>
        </CarouselContainer>
    );
}
