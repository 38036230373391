//style
import styled, { useTheme, css } from "../../../styling/styled-components";

// components
import Button from "../../common/Button";
import Text from "../../common/Text";

const StyledButton = styled(Button)`
     margin: 0px;
     text-align: left;
     justify-content: start;
     padding: 8px ${({ theme }) => theme.spacing.small}px;
     ${({ theme }) =>
         css`
             background-color: ${(props) =>
                 props.isSelected ? theme.colors.DEEP_BLUE_SEA : "transparent"};
             border: 1px solid
                 ${(props) =>
                     props.isSelected
                         ? theme.colors.DEEP_BLUE_SEA
                         : theme.colors.WAVE_STORM};
             &:hover {
                 background-color: ${(props) =>
                     props.isSelected
                         ? theme.colors.WAVE_STORM
                         : theme.colors.SEAGLASS};
             }
         `};
     min-width: ${({ theme }) =>
         theme.isTablet || theme.isMobile ? "max-content" : "fit-content"};' }
     
 `;

const LabelContainer = styled.div`
    align-items: center;
    display: flex;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

const ButtonLabel = styled(Text)`
    white-space: nowrap;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

export default function DropdownFilterButton({
    onClick,
    isSelected,
    children,
}) {
    const { colors } = useTheme();
    const labelColor = isSelected ? colors.BLANK_SLATE : colors.WAVE_STORM;
    const buttonType = isSelected ? "primary" : "secondary";

    return (
        <StyledButton
            isSelected={isSelected}
            onClick={onClick}
            type={buttonType}
        >
            <LabelContainer>
                <ButtonLabel type="body" size="medium" color={labelColor}>
                    {children}
                </ButtonLabel>
            </LabelContainer>
        </StyledButton>
    );
}
