//react
import { useEffect, useState, useRef } from "react";

//components
import AssetFilterButton from "./filters/DropdownFilterButton";
import GlobalDropdown from "../common/GlobalDropdown";
import Loadable from "../common/Loadable";

//styles
import styled, { useTheme } from "../../styling/styled-components";
import { fadeIn } from "./common/Animation";

//apis
import { useQuery } from "../../graphql/hooks/query";
import * as queries from "../../graphql/queries";

const FilterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    animation: ${fadeIn} 1s ease-in-out forwards;
    gap: ${({ theme }) => theme.spacing.small}px;
`;

export default function AssetFiltersSummary({ onClick }) {
    const [loading, setLoading] = useState(true);
    const [assetTypes, setAssetTypes] = useState();
    const [selectedButton, setSelectedButton] = useState(0);
    const [showDropdown, setShowDropdown] = useState(false);
    const screenWidth = window.innerWidth;
    const dropdownRef = useRef(null);
    const { isTablet, isDesktop } = useTheme();
    const displayAssetFilter = assetTypes?.length > 1 && !loading;

    let allAssetsOptions = { assetTypeId: null, name: "All asset types" };

    const [
        querylistAssetTypes,
        querylistAssetTypesData,
        querylistAssetTypesLoading,
        querylistAssetTypesErrors,
    ] = useQuery(queries.listAssetTypes);

    useEffect(() => {
        querylistAssetTypes();
    }, []);

    const handleResizeFilters = () => {
        if (isTablet) {
            if (assetTypes?.length > 4) {
                setShowDropdown(true);
            } else {
                setShowDropdown(false);
            }
        }
        if (isDesktop) {
            if (assetTypes?.length > 5) {
                setShowDropdown(true);
            } else {
                setShowDropdown(false);
            }
        }
    };

    useEffect(() => {
        const loadingTimeout = setTimeout(() => {
            setLoading(false);
        }, 200);
        return () => clearTimeout(loadingTimeout);
    }, []);

    useEffect(() => {
        const resizeTimeout = setTimeout(() => {
            handleResizeFilters();
        }, 0);
        return () => clearTimeout(resizeTimeout);
    }, [assetTypes, screenWidth, dropdownRef]);

    useEffect(() => {
        let alphabetizedAssets = querylistAssetTypesData?.results.sort((a, b) =>
            a.name.localeCompare(b.name)
        );

        if (querylistAssetTypesData?.results.length == 1) {
            setAssetTypes(alphabetizedAssets);
            return;
        }
        if (alphabetizedAssets && querylistAssetTypesData?.results.length > 1) {
            let allAssetOptions = [allAssetsOptions, ...alphabetizedAssets];
            setAssetTypes(allAssetOptions);
        }
    }, [querylistAssetTypesData]);

    const handleButtonClick = (item, index) => {
        setSelectedButton(index);
        onClick(item);
    };

    const submitSelectedAsset = (item) => {
        onClick(item);
    };

    const MultipleAssetTypes = () => {
        return (
            <Loadable dataLoaded={!showDropdown}>
                {assetTypes?.map((item, index) => {
                    return (
                        <AssetFilterButton
                            onClick={() => handleButtonClick(item, index)}
                            isSelected={selectedButton == index}
                        >
                            {item.name}
                        </AssetFilterButton>
                    );
                })}
            </Loadable>
        );
    };

    return (
        <FilterWrapper ref={dropdownRef}>
            <Loadable dataLoaded={displayAssetFilter}>
                <GlobalDropdown
                    dropdownConfig={{
                        options: assetTypes?.map(({ assetTypeId, name }) => ({
                            id: assetTypeId || "",
                            name,
                        })),

                        isOpen: showDropdown,
                    }}
                    onSubmit={(selectedValue) =>
                        submitSelectedAsset(selectedValue)
                    }
                    initialSelected={{
                        value: "",
                        label: "All asset types",
                    }}
                />

                <MultipleAssetTypes />
            </Loadable>
        </FilterWrapper>
    );
}
