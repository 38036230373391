//react
import { useState, useEffect } from "react";

//components
import HighlightHover from "../../common/HighlightHover";
import Text from "../Text";
import Displayable from "../Displayable";
import Loadable from "../Loadable";
import WhiteLoadingCard from "../WhiteLoadingCard";

//style
import styled, { useTheme } from "../../../styling/styled-components";

//images
import { ReactComponent as ChevronBlue } from "../../../assets/vectors/ChevronBlue.svg";
import { ReactComponent as ChevronBlueDown } from "../../../assets/vectors/ChevronBlueDown.svg";

const AccordionBox = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    z-index: 1;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

type Props = {
    accordionText: string;
    overlayId: string;
    size: string;
};

export default function AccordionLabel({
    accordionText,
    children,
    overlayId = "",
    size = "default",
    openAccordion = false,
}: Props) {
    const [accordionOpen, setAccordionOpen] = useState(openAccordion);
    const text = accordionOpen ? accordionText.open : accordionText.default;
    const textType = size == "default" ? "label" : "header";

    const { colors } = useTheme();

    const toggleAccordion = () => {
        setAccordionOpen(!accordionOpen);
    };

    //We set the accordionOpen state to false whenever the overlayId changes so that when we switch directly between overlays, the accordion defaults to closed
    useEffect(() => {
        setAccordionOpen(openAccordion);
    }, [overlayId]);

    return (
        <>
            <Loadable dataLoaded={text} LoadingComponent={WhiteLoadingCard}>
                <AccordionBox
                    type="other"
                    size="large"
                    onClick={toggleAccordion}
                >
                    <Text
                        size="medium"
                        type={textType}
                        color={colors.WAVE_STORM}
                    >
                        {text}
                    </Text>
                    <HighlightHover>
                        <Displayable
                            displayPrimaryComponent={accordionOpen}
                            SecondaryComponent={ChevronBlueDown}
                        >
                            <ChevronBlue />
                        </Displayable>
                    </HighlightHover>
                </AccordionBox>
            </Loadable>
            <Displayable displayPrimaryComponent={accordionOpen}>
                {children}
            </Displayable>
        </>
    );
}
