// react
import { useEffect, useRef, useState } from "react";

// style
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css";
import styled, { css, useTheme } from "styled-components/macro";

// components
import { Calendar, utils } from "@amir04lm26/react-modern-calendar-date-picker";
import Line from "./Line";
import Text from "./Text";
import DropdownLabel from "./dropdown/DropdownLabel";
import ConfirmButton from "./dropdown/ConfirmButton";
import FlyoutForm from "./dropdown/FlyoutForm";
import OptionsContainer from "./dropdown/OptionsContainer";
import RadioButtons from "./dropdown/RadioButtons";
import FormChildrenWrapper from "./dropdown/FormChildrenWrapper";

// assets
import { ReactComponent as CloseIcon } from "../../assets/vectors/Close.svg";

// utils
import { format } from "date-fns";
import useClickOutside from "../../utils/useClickOutside";
import { CalendarIcons } from "./datepicker/CalendarIcons";

const STRINGS = {
    APPLY_SELECTION: "Apply selection",
    BLURB: "Includes events that occurred within the selected date range",
    CALENDAR_LABEL_START: "Start/End: ",
    CLEAR: "Clear",
    CUSTOM_RANGE_RADIO_LABEL: "customDateRange",
    SELECT_LABEL: "Select a day or date range",
};

// styled components
const Blurb = styled(Text)`
    margin-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const CalendarContainer = styled.div`
    margin-right ${({ theme }) => theme.spacing.xmlarge}px;
`;

const CalLabelAndClearContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ClearButtonContainer = styled.div`
    cursor: pointer;
`;

const DropDownContainer = styled.div`
    position: relative;
`;

const RadioForm = styled.div`
    width: 210px;
`;

const StyledCloseIcon = styled(CloseIcon)`
    margin-left: ${({ theme }) => theme.spacing.tiny}px;
`;

type DateRangeItem = {
    value: string;
    label: string;
    selected: boolean;
};

type Props = {
    initDaysBack?: number | string;
    initDateRange?: [Date, Date];
    processDateChange: (
        isRelative: boolean,
        daysBack: number | string,
        range: [Date, Date]
    ) => void;
    dateRangeItems: DateRangeItem[];
    style?: React.CSSProperties;
    className?: string;
};

export default function DatePicker({
    initDaysBack,
    initDateRange,
    processDateChange,
    style,
    dateRangeItems,
    className,
    ...props
}: Props) {
    // state
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedDayRange, setSelectedDayRange] = useState({
        from: null,
        to: null,
    });
    const [selectedValue, setSelectedValue] = useState("");
    const [buttonLabel, setButtonLabel] = useState("");
    const [disableButtonInitial, setDisableButtonInitial] = useState(true);
    const [showOptions, setShowOptions] = useState(false);
    const [calendarRangeLabel, setCalendarRangeLabel] = useState(
        STRINGS.CALENDAR_LABEL_START
    );
    const [buttonClickable, setButtonClickable] = useState(false);

    const { colors, spacing } = useTheme();

    //refs click outside
    const dropdownRef = useRef("date-picker-dropdown-button");
    const flyoutRef = useRef("date-picker-flyout");

    useClickOutside(
        flyoutRef,
        () => {
            setShowOptions(false);
        },
        [dropdownRef]
    );

    // Helper functions
    const checkSubmitButtonStatus = () => {
        if (
            (!selectedDayRange.from &&
                selectedValue == STRINGS.CUSTOM_RANGE_RADIO_LABEL) ||
            disableButtonInitial
        ) {
            setButtonClickable(false);
        } else {
            setButtonClickable(true);
        }
    };

    const changeSelectionAndToggleCalendar = (event) => {
        const radioResult = event.target.value;
        if (radioResult == STRINGS.CUSTOM_RANGE_RADIO_LABEL) {
            setShowCalendar(true);
        } else if (selectedValue == STRINGS.CUSTOM_RANGE_RADIO_LABEL) {
            setShowCalendar(false);
        }
        setDisableButtonInitial(false);
        setSelectedValue(radioResult);
    };

    const clearCalendarRange = () => {
        setSelectedDayRange({
            from: null,
            to: null,
        });
        setCalendarRangeLabel(STRINGS.CALENDAR_LABEL_START);
    };

    const setCalendarRange = (range) => {
        setSelectedDayRange(range);
        let calendarRangeStart = null;
        if (!!range.from) {
            calendarRangeStart = new Date(
                range.from.year,
                range.from.month - 1,
                range.from.day
            );
        }

        let calendarRangeEnd = null;
        if (!!range.to) {
            calendarRangeEnd = new Date(
                range.to.year,
                range.to.month - 1,
                range.to.day
            );
        }
        setCalendarRangeLabel(
            getCalendarRangeLabel(calendarRangeStart, calendarRangeEnd)
        );
    };

    const getCalendarRangeLabel = (startDate, endDate) => {
        if (!!endDate) {
            if (startDate.getYear() == endDate.getYear()) {
                if (startDate.getMonth() == endDate.getMonth()) {
                    if (startDate.getDay() == endDate.getDay()) {
                        return (
                            STRINGS.CALENDAR_LABEL_START +
                            format(startDate, "MMM. d, yyyy")
                        );
                    } else {
                        return (
                            STRINGS.CALENDAR_LABEL_START +
                            format(startDate, "MMM. d - ") +
                            format(endDate, "d, yyyy")
                        );
                    }
                } else {
                    return (
                        STRINGS.CALENDAR_LABEL_START +
                        format(startDate, "MMM. d - ") +
                        format(endDate, "MMM. d, yyyy")
                    );
                }
            } else {
                return (
                    STRINGS.CALENDAR_LABEL_START +
                    format(startDate, "MMM. d, yyyy - ") +
                    format(endDate, "MMM. d, yyyy")
                );
            }
        } else {
            return (
                STRINGS.CALENDAR_LABEL_START + format(startDate, "MMM. d, yyyy")
            );
        }
    };

    const submitDateChange = (event) => {
        event.preventDefault();
        const form = event.target;
        const formJson = Object.fromEntries(new FormData(form).entries());
        const radioResult = formJson["optionSelected"];

        if (radioResult == STRINGS.CUSTOM_RANGE_RADIO_LABEL) {
            if (!!selectedDayRange.from) {
                const calendarRangeStart = new Date(
                    selectedDayRange.from.year,
                    selectedDayRange.from.month - 1,
                    selectedDayRange.from.day,
                    0,
                    0,
                    1
                );

                if (!!selectedDayRange.to) {
                    const calendarRangeEnd = new Date(
                        selectedDayRange.to.year,
                        selectedDayRange.to.month - 1,
                        selectedDayRange.to.day,
                        23,
                        59,
                        59
                    );
                    processDateChange(false, null, [
                        calendarRangeStart,
                        calendarRangeEnd,
                    ]);
                } else {
                    const calendarRangeStartEnd = new Date(
                        selectedDayRange.from.year,
                        selectedDayRange.from.month - 1,
                        selectedDayRange.from.day,
                        23,
                        59,
                        59
                    );
                    processDateChange(false, null, [
                        calendarRangeStart,
                        calendarRangeStartEnd,
                    ]);
                }

                setButtonLabel(calendarRangeLabel);
            } else {
                alert(
                    "You did not specify a date range, so the data are showing the last selected range."
                );
            }
        } else {
            submitRelativeDateRange(parseInt(radioResult));
        }
        setShowOptions(false);
    };

    const submitRelativeDateRange = (newDaysBack, executeProcess = true) => {
        setSelectedValue(newDaysBack.toString());
        for (let index in dateRangeItems) {
            if (dateRangeItems[index].value == newDaysBack.toString()) {
                setButtonLabel(dateRangeItems[index].label);
                break;
            }
        }

        if (executeProcess) {
            processDateChange(true, newDaysBack);
        }
    };

    const submitAbsoluteDateRange = (
        startDate,
        endDate,
        executeProcess = true
    ) => {
        setSelectedValue(STRINGS.CUSTOM_RANGE_RADIO_LABEL);

        setButtonLabel(getCalendarRangeLabel(startDate, endDate));
        if (executeProcess) {
            processDateChange(true, null, [endDate, startDate]);
        }
    };

    // effects
    useEffect(() => {
        const selectedDate = dateRangeItems.find((date) => date.selected);

        if (selectedDate) {
            setSelectedValue(selectedDate.value);
            setButtonLabel(selectedDate.label);
        }
        if (initDaysBack) {
            submitRelativeDateRange(initDaysBack, false);
        } else if (!!initDateRange && !!initDateRange[0]) {
            submitAbsoluteDateRange(
                new Date(initDateRange[1]),
                new Date(initDateRange[0]),
                false
            );
        } else {
            processDateChange(true, parseInt(selectedValue));
        }
    }, []);

    useEffect(() => {
        checkSubmitButtonStatus();
    }, [disableButtonInitial, selectedValue, selectedDayRange]);

    // subcomponents
    const CalendarForm = () => {
        if (showCalendar) {
            return (
                <CalendarContainer>
                    <CalLabelAndClearContainer>
                        <Text
                            type="label"
                            size="medium"
                            color={colors.DEEP_BLUE_SEA}
                        >
                            {calendarRangeLabel}
                        </Text>
                        {!!selectedDayRange.from && (
                            <ClearButtonContainer>
                                <Text
                                    color={colors.DEEP_BLUE_SEA}
                                    type="label"
                                    size="medium"
                                    onClick={clearCalendarRange}
                                >
                                    {STRINGS.CLEAR} <StyledCloseIcon />
                                </Text>
                            </ClearButtonContainer>
                        )}
                    </CalLabelAndClearContainer>
                    <Line />
                    <Calendar
                        value={selectedDayRange}
                        onChange={setCalendarRange}
                        maximumDate={utils().getToday()}
                        colorPrimary={colors.WAVE_STORM}
                        colorPrimaryLight={colors.MARINE_LAYER}
                    />
                </CalendarContainer>
            );
        }
        return <></>;
    };

    const toggleDropdown = () => {
        setShowOptions(!showOptions);
    };

    return (
        <DropDownContainer style={style} className={className}>
            <div ref={dropdownRef}>
                <DropdownLabel
                    toggleDropdown={toggleDropdown}
                    label={buttonLabel}
                    isOpen={showOptions}
                >
                    <CalendarIcons showDefault={showOptions} />
                </DropdownLabel>
            </div>
            {showOptions && (
                <div ref={flyoutRef}>
                    <FlyoutForm onSubmit={submitDateChange} alignRight={true}>
                        <FormChildrenWrapper>
                            <CalendarForm />
                            <RadioForm>
                                {initDateRange && (
                                    <Blurb
                                        type="body"
                                        size="tiny"
                                        color={colors.DEEP_BLUE_SEA}
                                    >
                                        {STRINGS.BLURB}
                                    </Blurb>
                                )}
                                <OptionsContainer>
                                    <RadioButtons
                                        options={dateRangeItems}
                                        onChange={
                                            changeSelectionAndToggleCalendar
                                        }
                                        selectedOption={selectedValue}
                                    />
                                    <ConfirmButton
                                        clickable={buttonClickable}
                                    />
                                </OptionsContainer>
                            </RadioForm>
                        </FormChildrenWrapper>
                    </FlyoutForm>
                </div>
            )}
        </DropDownContainer>
    );
}
