//react
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

//apis
import * as queries from "../../../graphql/queries";
import useGraphClient from "../../../hooks/useGraphClient";

//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import StationAccordion from "./StationAccordion";
import Loadable from "../../common/Loadable";
import LoadingCard from "./LoadingCard";
import Displayable from "../../common/Displayable";
import Text from "../../common/Text";
import MenuCarousel from "./MenuCarousel";
import GlobalDropdown from "../../common/GlobalDropdown";

//utils
import queryString from "query-string";

const Wrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

const NoResultsWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xlarge}px;
`;

const CarouselDiv = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.small}px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.ASPHALT};
`;

const LoadingStateDiv = styled.div`
    width: 124px;
    height: 30px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
`;

const FooterWrapper = styled.div`
    display: flex;
    bottom: 0;
    padding-top: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const STRINGS = {
    NO_RESULTS: (term) =>
        `No results for ${term} in this menu. Try adjusting the menu or other filters above.`,
    TIMESTAMP: "Planned menu item amounts are updated every day at 7:00am PST.",
};

type Props = {
    searchTerm: String;
};

export default function MenuTabs({ searchTerm }: Props) {
    const { colors, isTablet } = useTheme();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const graphClient = useGraphClient();

    const [menuResults, setMenuResults] = useState(null);
    const [productionStats, setProductionStats] = useState();
    let [searchParams, setSearchParams] = useSearchParams();

    const [prodStatsInitialized, setProdStatsInitialized] = useState(false);

    const [stationIdList, setStationIdList] = useState([]);

    const excludeEmpty = searchParams.get("excludeEmpty") || "TRUE";

    const [emptyCard, setEmptyCard] = useState(false);

    const today = new Date();
    const queryDate = new Date(queryParams?.date);

    const [menuId, setMenuId] = useState([]);
    const [menuName, setMenuName] = useState();

    const [showDropdown, setShowDropdown] = useState(true);

    const fromDate =
        isNaN(queryDate) || !queryDate
            ? today.toISOString().split("T")[0]
            : queryDate?.toISOString().split("T")[0];

    const getMenuData = async () => {
        try {
            const result = await graphClient.graphql({
                query: queries.menus,
                variables: {
                    locationId: queryParams.locationIds,
                    fromDate: fromDate,
                    toDate: fromDate,
                },
            });
            const data = result?.data?.menus?.results;

            // ProductionSummary released on 2025-02-03. All menus prior to this point;
            // do not have names from the v1 schema so this filter excludes those.
            const filteredData = data.filter((data) => data.name != null);
            setMenuResults(filteredData);

            const stationIds = () => {
                return data.reduce((acc, obj) => {
                    acc[obj.name] = acc[obj.name] || [];
                    acc[obj.name].push(obj.stationName);
                    return acc;
                }, {});
            };
            setStationIdList(stationIds());
            initializeProductionStats();
        } catch (err) {
            console.error("Error getting menu data", err);
        }
    };

    const getProductionStats = async () => {
        try {
            const result = await graphClient.graphql({
                query: queries.productionStats,
                variables: {
                    fromDate: fromDate,
                    toDate: fromDate,
                    locationIds: [queryParams.locationIds],
                    menuIds: menuId,
                    searchQuery: searchTerm,
                    excludeEmpty: excludeEmpty,
                },
            });
            const data = result?.data?.productionStats.productionStats;

            sortAlphabeticalByStationId(data);
            completeProductionStatCardItems(data);
        } catch (err) {
            console.error("Error getting production stats", err);
        }
    };

    const initializeProductionStats = async () => {
        try {
            const result = await graphClient.graphql({
                query: queries.productionStats,
                variables: {
                    fromDate: fromDate,
                    toDate: fromDate,
                    locationIds: [queryParams.locationIds],
                    menuIds: [],
                    searchQuery: searchTerm,
                    excludeEmpty: excludeEmpty,
                },
            });
            const data = result?.data?.productionStats.productionStats;

            sortAlphabeticalByStationId(data);
            completeProductionStatCardItems(data);
            setProdStatsInitialized(true);
        } catch (err) {
            console.error("Error getting production stats", err);
        }
    };

    useEffect(() => {
        setMenuResults([]);
        setProductionStats([]);
        setStationIdList([]);
        if (queryParams?.locationIds && fromDate) {
            getMenuData();
        }
    }, [queryParams.locationIds, queryParams.date]);

    useEffect(() => {
        getProductionStats();
    }, [menuId, searchTerm, excludeEmpty]);

    useEffect(() => {
        if (productionStats && prodStatsInitialized) {
            finalMenuFormat()?.map((item, index) => {
                if (item.name == menuName) {
                    setMenuId(item.id);
                }
            });
            setProdStatsInitialized(false);
        }
    }, [prodStatsInitialized, productionStats]);

    const orderMenuItems = ["Breakfast", "Lunch", "Dinner"];

    const sortedMeals = menuResults?.sort((a, b) => {
        const meal1Index = orderMenuItems.findIndex((keyword) =>
            a.name.includes(keyword)
        );
        const meal2Index = orderMenuItems.findIndex((keyword) =>
            b.name.includes(keyword)
        );

        if (meal1Index !== -1 && meal2Index !== -1) {
            return meal1Index - meal2Index;
        }
        if (meal1Index !== -1) return -1;
        if (meal2Index !== -1) return 1;
        return 0;
    });

    const completeProductionStatCardItems = (data) => {
        setEmptyCard(false);
        const groupedProdStats = groupMenuItemsByStationId(data);
        const existingStationIds = extractStationIds(groupedProdStats);

        const findMissingStations = () => {
            const missingStations = {};
            for (const [category, stations] of Object.entries(stationIdList)) {
                if (category == menuName && queryParams.menuId) {
                    const missingIds = stations.filter(
                        (station) => !existingStationIds.includes(station)
                    );
                    if (missingIds?.length > 0) {
                        missingStations[category] = missingIds;
                    }
                }
            }
            return missingStations;
        };

        const missingStations = findMissingStations();

        for (const [category, stations] of Object.entries(missingStations)) {
            if (category == menuName) {
                stations?.forEach((stationName) => {
                    groupedProdStats.push([{ stationName, noResults: true }]);
                });
                setEmptyCard(true);
            }
        }
        setProductionStats(groupedProdStats);
    };

    const groupMenus = () => {
        return sortedMeals?.reduce((acc, obj) => {
            const key = obj.name;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    };

    const formatMenusForTabs = () => {
        const data = groupMenus();
        if (data) {
            return Object?.keys(data)?.map((key) => ({
                name: key,
                id: data[key]?.map((item) => item?.id),
            }));
        }
    };

    const finalMenuFormat = () => {
        return formatMenusForTabs(groupMenus());
    };

    const handleTabChange = (name) => {
        finalMenuFormat().map((menu, index) => {
            if (name === menu.name) {
                searchParams.set("menuId", `${menu.id.join(",")}`);
                setMenuId(menu.id);
            }
        });

        searchParams.set("menuName", name);
        setSearchParams(searchParams);
        setMenuName(name);
    };

    const sortAlphabeticalByStationId = (arr) => {
        return arr.sort((a, b) => a.stationName - b.stationName);
    };

    const groupMenuItemsByStationId = (arr) => {
        const grouped = {};
        arr?.forEach((obj) => {
            if (!grouped[obj.stationName]) {
                grouped[obj.stationName] = [];
            }
            grouped[obj.stationName].push(obj);
        });
        return Object.values(grouped);
    };

    const extractStationIds = (arr) => {
        const allIds = arr.flatMap((subArray) =>
            subArray.map((obj) => obj.stationName)
        );
        return [...new Set(allIds)];
    };

    useEffect(() => {
        setMenuName(searchParams.get("menuName"));
    }, []);

    useEffect(() => {
        finalMenuFormat()?.map((item, index) => {
            if (item.name == menuName) {
                setMenuId(item.id);
            }
        });
    }, [menuName]);

    return (
        <Wrapper>
            <Loadable
                dataLoaded={menuResults}
                LoadingComponent={LoadingStateDiv}
            >
                <Displayable
                    displayPrimaryComponent={
                        finalMenuFormat()?.length > 0 &&
                        finalMenuFormat()?.length < 8 &&
                        !isTablet
                    }
                >
                    <CarouselDiv>
                        <MenuCarousel
                            tabOptions={finalMenuFormat()}
                            handleTabChange={handleTabChange}
                            initialSelected={menuName}
                        />
                    </CarouselDiv>
                </Displayable>
                <Displayable
                    displayPrimaryComponent={
                        finalMenuFormat()?.length > 8 ||
                        (isTablet && finalMenuFormat()?.length > 0)
                    }
                >
                    <GlobalDropdown
                        dropdownConfig={{
                            options: finalMenuFormat()?.map(({ id, name }) => ({
                                id: name,
                                name: name,
                            })),
                            isOpen: showDropdown,
                        }}
                        onSubmit={(selectedValue, name) =>
                            handleTabChange(selectedValue, name)
                        }
                        initialSelected={menuName}
                    />
                </Displayable>
            </Loadable>
            <Displayable displayPrimaryComponent={searchTerm && emptyCard}>
                <NoResultsWrapper>
                    <Text
                        type="label"
                        size="small"
                        color={colors.DEEP_BLUE_SEA}
                    >
                        {STRINGS.NO_RESULTS(searchTerm)}
                    </Text>
                </NoResultsWrapper>
            </Displayable>
            <Loadable
                dataLoaded={productionStats}
                LoadingComponent={LoadingCard}
            >
                <Displayable displayPrimaryComponent={menuResults?.length > 0}>
                    {productionStats?.map((item, index) => {
                        return <StationAccordion stationData={item} />;
                    })}
                    <Displayable
                        displayPrimaryComponent={productionStats?.length !== 0}
                    >
                        <FooterWrapper>
                            <Text
                                type="body"
                                size="small"
                                color={colors.EXHAUST}
                            >
                                {STRINGS.TIMESTAMP}
                            </Text>
                        </FooterWrapper>
                    </Displayable>
                </Displayable>
            </Loadable>
        </Wrapper>
    );
}
