export const getTodaysDate = () => {
    const today = new Date();

    let todaysDate = {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate(),
    };
    return todaysDate;
};
